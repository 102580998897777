import React, { useState, useEffect } from "react";
import { ClubLogo } from "../ClubLogo";
import { PlayerImage } from "../PlayerImg";
import { useLocation } from "react-router-dom";

export const Teams = () => {
  const [players, setPlayers] = useState([]);
  const teams = [
    "Gujarat Titans",
    "Kolkata Knight Riders",
    "Mumbai Indians",
    "Chennai Super Kings",
    "Delhi Capitals",
    "Punjab Kings",
    "Lucknow Super Giants",
    "Rajasthan Royals",
    "Sunrisers Hyderabad",
    "Royal Challengers Bengaluru"
  ];

  const teamIds = {
    'Mumbai Indians': 6000716,
    'Punjab Kings': 6000712,
    'Rajasthan Royals': 6000715,
    'Chennai Super Kings': 6000713,
    'Kolkata Knight Riders': 6000711,
    'Sunrisers Hyderabad': 6000811,
    'Delhi Capitals': 6000714,
    'Royal Challengers Bengaluru': 6000710,
    'Lucknow Super Giants': 6000949,
    'Gujarat Titans': 6000948
  };

  //const teamUrl = decodeURIComponent(window.location.pathname.split('/teams/')[1]);
  const xlocation = useLocation();
  const queryParams = new URLSearchParams(xlocation.search);
  const teamUrl=queryParams.get("team");
 
  useEffect(() => {
    const fetchTeam = async () => {
        try {
            if (teamUrl !== null) {
                const teamId = teamIds[teamUrl];
                const res = await fetch(`${process.env.API_URL}/team/${teamId}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-api-key': process.env.API_KEY
                    },
                });
                if (res.status === 200) {
                    const data = await res.json();
                    setPlayers(data.players);
                }
            }
        } catch (error) {
            console.error('Network error:', error);
        }
    };

    fetchTeam();

    return () => {
        // Cleanup if needed
    };
}, [teamUrl]);

useEffect(() => {
  if (teamUrl !==null) {
    document.title = teamUrl +" | Cricket8";  
  }  else {
    document.title = "TEAMS | Cricket8";
  }
}, []);

  return (
    <>
      {teamUrl===null ? (
        <div id='content' className='min-h-[75vh] mt-0px md:mt-[20px]'>
          <div className='TeamPage md:mt-10 mb-[20px]'>
            <div className='flex flex-col md:flex-row w-full'>
              <div className='col1 md:flex-shrink-0 md:mr-8 md:min-w-[40%]'>
                <h2 className='text-[14px] md:text-[20px]'>IPL 2024</h2>
                <h1 className='text-[30px] md:text-[55px]'>TEAMS</h1>
              </div>
            </div>
            <div className='mt-[20px] flex flex-wrap gap-2'>
              {teams.map((team, idx) => (
                  <div className="w-full md:w-[365px] h-[100px]">
                    <a key={idx} className='menuLink' href={`/teams?team=${team}`}>
                    <div className="w-[100%] h-[100%] bg-[#ffffff26] flex">
                      <div className='text-center pt-[25px] pl-[20px]'>
                        <ClubLogo teamName={team} height={'60px'} width={'60px'} />
                      </div>
                      <div className='flex-grow font-anton text-[18px] text-left pt-[35px] pl-[20px] uppercase'>{team}</div>
                      <div className='w-[30px] text-right pt-[30px] mr-[20px]'>
                        <div className="w-[30px] h-[30px]">
                          <div className="w-[30px] h-[30px] top-0 left-0 pt-[6px]" style={{backgroundColor: 'rgba(255,255,255,0.2)'}}>
                            <img className="inline w-[28px] h-[12px] top-[4px] left-[1px]" alt="Vector stroke" src="/img/vector-stroke.svg" />
                          </div>
                        </div>
                      </div>
                    </div>
                    </a>
                  </div>
              ))}
            </div>
          </div>
        </div>
      ) : (
        <div id='content' className='min-h-[75vh] mt-[20px]'>
          <div className='TeamPage md:mt-10 mb-[20px]'>
            <div className='flex flex-col md:flex-row w-full'>
              <div className='col1 md:flex-shrink-0 md:mr-8 md:min-w-[40%]'>
                <h2 className='text-[14px] md:text-[20px]'>IPL 2024</h2>
                <h1 className='text-[30px] md:text-[55px]'>{teamUrl.toUpperCase()}</h1>
              </div>
            </div>
            <div className='mt-10 md:flex md:flex-wrap w-full'>
              {players.map((player, idx) => (
                <div key={idx} className='w-full md:w-1/2 mb-3 px-2'>
                    <div className='flex bg-white bg-opacity-15 w-full h-[77px] mr-[3px] items-center font-chakra '>
                      <div className='w-[60px] ml-[5px] bg-white bg-opacity-15'>
                        <div><PlayerImage teamName={teamUrl} playerName={player.name} height='60px' width='60px' /></div>
                      </div>
                      <div className='pl-[20px] w-[40%]'>
                        <div className='bold'>{player.name}</div>
                        <div>AGE: {player.age}</div>
                      </div>
                      <div className='pl-[20px]'>
                        <div>BAT STYLE: {player.batstyle}</div>
                        <div>BOWL STYLE: {player.bowlstyle}</div>
                      </div>
                    </div>
                  </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </>
  );
};
export default Teams;